<template>
  <div>
    <v-card>
      <v-card-title class="fs-14 purple--text py-1">
        {{ $t("labels.scan_multiple") }}
        <v-spacer></v-spacer>
        Active: {{ items.length }}/{{ codes.length }}
      </v-card-title>

      <v-card-text>
        <div class="mb-1">
          <input-qr-scan-model
            ref="inputQr"
            v-model="code"
            :label="$t('labels.uid')"
            @keyupEnter="inputScan"
          ></input-qr-scan-model>
        </div>

        <!-- <v-card outlined style="min-height: 80px;" class="pa-1">
          <span v-for="(code, index) in showCodes" :key="`c_${code}_${index}`">
            <span :class="{'error--text': errorCodes.includes(code)}">{{ code }}</span>
            <span v-if="index < (showCodes.length - 1)">,&nbsp;</span>
          </span>
        </v-card> -->
      </v-card-text>

      <v-card-title dense class="fs-14 error--text py-1">
        {{ $t("labels.uid_need_remove") }}
        <v-spacer></v-spacer>
        Remove: {{ removeCount }}
      </v-card-title>

      <v-card-text>
        <v-simple-table class="table-padding-2-no-top mb-5">
          <template v-slot:default>
            <thead class="v-data-table-header">
              <tr>
                <th role="columnheader" class="text-center" style="width: 80px">
                  {{ $t("labels.sku") }}
                </th>
                <th role="columnheader" class="text-center">
                  {{ $t("labels.uid") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="text-center"
                v-for="(sku, index) in errorSkus"
                :key="`${index}_${sku}`"
              >
                <td>{{ sku }}</td>
                <td>{{ errorItems[sku].join("; ") }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <div class="mb-1">
          <input-qr-scan-model
            v-model="remove_code"
            :label="$t('labels.scan_for_remove')"
            @keyupEnter="inputRemove"
          ></input-qr-scan-model>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="cancel">
          {{ $t("labels.cancel") }}
        </v-btn>
        <v-btn
          color="green darken-1"
          text
          @click="confirm"
          :disabled="isDisabledBtn"
        >
          {{ $t("labels.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { debounce } from "@/libs/helpers";

export default {
  name: "ScanMultipleStamp",
  components: {},
  props: {},
  data: () => ({
    isLoading: false,
    code: null,
    remove_code: null,
    codes: [],
    items: [],
    errorCodes: [],
    notFoundItems: [],
    wrongStatusItems: [],
    wrongReceiptItems: [],
    wrongDestinationItems: [],
  }),
  computed: {
    isDisabledBtn() {
      return (
        this.isLoading ||
        this.items.length === 0 ||
        this.notFoundItems.length > 0 ||
        this.wrongStatusItems.length > 0 ||
        this.wrongReceiptItems.length > 0 ||
        this.wrongDestinationItems.length > 0
      );
    },
    removeCount() {
      return (
        this.notFoundItems.length +
        this.wrongStatusItems.length +
        this.wrongReceiptItems.length +
        this.wrongDestinationItems.length
      );
    },
    showCodes() {
      const codes = [...this.codes].slice(0, 20);
      return codes.map((code) => {
        let reCode = null;
        if (code.includes("@")) {
          const codeSpl = code.split("@");
          reCode = codeSpl[1];
        } else {
          reCode = code;
        }
        return reCode;
      });
    },
    errorItems() {
      const skuItems = {};

      if (this.notFoundItems.length > 0) {
        [...this.notFoundItems].forEach((code) => {
          if (code.includes("@")) {
            const [sku, uid] = code.split("@");
            if (!skuItems[sku]) {
              skuItems[sku] = [];
            }
            skuItems[sku].push(uid);
          } else {
            if (!skuItems[""]) {
              skuItems[""] = [];
            }
            skuItems[""].push(code);
          }
        });
        /* skuItems[''] = [...this.notFoundItems].map(code => {
          let reCode = null
          if (code.includes('@')) {
            const codeSpl = code.split('@')
            reCode = codeSpl[1]
          } else {
            reCode = code
          }
          return reCode
        }) */
      }

      [
        ...this.wrongStatusItems,
        ...this.wrongReceiptItems,
        ...this.wrongDestinationItems,
      ].forEach((item) => {
        if (!skuItems[item.sku]) {
          skuItems[item.sku] = [];
        }
        skuItems[item.sku].push(item.barcode || item.uid);
      });

      return skuItems;
    },
    errorSkus() {
      return Object.keys(this.errorItems);
    },
  },
  mounted() {
    this.focusInputQr();
  },
  methods: {
    focusInputQr() {
      setTimeout(() => {
        this.$refs.inputQr.focusInput();
      }, 200);
    },
    cancel() {
      this.$emit("cancel");
    },
    inputRemove() {
      if (!this.codes.includes(this.remove_code)) {
        this.$vToastify.error(
          this.$t("messages.wrong_code", { code: this.remove_code })
        );
        document.getElementById("error_sound_player").play();
        this.remove_code = null;
        return false;
      }
      this.codes = [...this.codes].filter((code) => code != this.remove_code);
      this.remove_code = null;
      this.checkUid();
    },
    inputScan() {
      if (!this.code) {
        return false;
      }
      const code = this.code.trim();
      if (!this.codes.includes(code)) {
        this.codes.unshift(code);
      }
      this.code = null;
      this.checkUid();
    },
    checkUid: debounce(function () {
      console.log(this.codes);
      this.isLoading = true;

      httpClient
        .post("/goods-receipt/v1/get-multiple-has-stamp", {
          codes: this.codes,
        })
        .then(({ data }) => {
          this.items = [...data.items];
          this.errorCodes = [...data.errorCodes];
          this.notFoundItems = [...data.notFoundItems];
          this.wrongStatusItems = [...data.wrongStatusItems];
          this.wrongReceiptItems = [...data.wrongReceiptItems];
          this.wrongDestinationItems = [...data.wrongDestinationItems];
          this.isLoading = false;
          console.log("data", data);
        })
        .catch((e) => {
          const errMsg =
            (e.response &&
              e.response.data &&
              e.response.data.error &&
              e.response.data.error.message) ||
            null;
          this.$vToastify.error(errMsg);
          this.isLoading = false;
          document.getElementById("error_sound_player").play();
        });
    }, 1000),
    confirm() {
      if (this.isDisabledBtn) {
        return false;
      }

      this.$emit("onItemScanned", this.items);
    },
  },
};
</script>

<style scoped></style>
